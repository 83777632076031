const translations = {
    nl: {
        "Show more": "Toon meer",
        "BestB": "BestB",
        "Doorzoek klanten": "Doorzoek klanten",
        "Filter": "Filter",
        "Close": "Close",
        "Premium selection": "Premium selection",
        "Private label": "Private label",
        "Bio": "Bio",
        "Bakery": "Bakery",
        "Loading": "Laden",
        "Reset filters": "Filters resetten",
        "Prijs": "Prijs",
        "Hoeveelheid": "Hoeveelheid",
        "Eenheid": "Verpakking",
        "Pallet": "Pallet",
        "ProductenPerDoos": "Stuks per verpakking",
        "AantalPerOmverpakking": "Aantal verpakkingen in",
        "Opmerking": "Opmerking",
        "Toevoegen": "Toevoegen",
        "Verwijderen": "Verwijderen",
        "Opslaan": "Opslaan",
        "Bestellen": "Bestellen",
        "Aanpassen": "Aanpassen",
        "products - page": "producten - pagina",
        "Vorige periode": "Vorige periode",
        "Huidige periode": "Huidige periode",
        "Laatst besteld" : "Laatst besteld",
        "ob": "OB",
        "new": "NEW",
        "promo": "promo",
        "stuks": "stuks",
        "Technical sheet": "Technische fiche",
        "Download technical sheet": "Download technische fiche",
        "Aantal": "Aantal",
        "Algemeen": "Algemeen",
        "Categorieën": "Categorieën",
        "Merken": "Merken",
        "Materiaal": "Materiaal",
        "Aantal in doos": "Aantal per eenheid",
        "Bak type": "Baktype",
        "Baktijd": "Baktijd",
        "Ontdooitijd": "Ontdooitijd",
        "Oven temperatuur": "Oven temperatuur",
        "Rijstijd": "Rijstijd",
        "Basisassortiment": "Basisassortiment",
        "Op bestelling": "Op bestelling",
        "Nieuwe producten": "Nieuwe producten",
        "Promoties": "Promoties",
        "Log in to order": "Inloggen",
        "tot": "tot",
        "Toon bestelhistoriek": "Toon bestelhistoriek",
        "Bestelhistoriek": "Bestelhistoriek",
        "Toon alle producten": "Toon alle producten",
        "Alle producten": "Alle producten",
        "Zoek jouw product op nummer of naam": "Zoek jouw product op nummer of naam",
        productNotAvailable: "Het geselecteerde product is op dit moment niet beschikbaar, we kunnen je wel volgende alternatieven aanbieden:",
        productsNotAvailable: "Beste klant, enkele producten uit jouw bestelling zijn op dit moment niet beschikbaar, we kunnen je wel alternatieven aanbieden voor volgende producten:",
        "Doorzoek producten en klanten": "Doorzoek producten en klanten",
        "Products": "Producten",
        "Customers": "Klanten",
        "Geen klant geselecteerd": "Geen klant geselecteerd",
        "Accept": "OK",
        "Cancel": "Annuleren",
        "Bevestig selectie": "Bevestig selectie",
        warningDeleteOrder: "<strong class='block text-18 text-red-500'>Bent u zeker dat u deze bestelling wil verwijderen?</strong>Dit kan niet ongedaan worden gemaakt.",
        "Nieuwe bestelling": "Nieuwe bestelling",
        "Naam": "Naam",
        "Omschrijving": "Omschrijving",
        "Totaal": "Totaal",
        "Artikel": "Artikel",
        "Bv. Bestelling éclairs": "Bv. Bestelling éclairs",
        "Bv. Alle nodige basisproducten voor éclairs.": "Bv. Alle nodige basisproducten voor éclairs.",
        "Producten in bestelling": "Producten in bestelling",
        "Bestelling verwijderen...": "Bestelling verwijderen...",
        "Sorteren": "Sorteren",
        "Sales": "Sales",
        "Voor sales": "Voor sales",
        "Prijs hoog - laag": "Hoogste prijs",
        "Prijs laag - hoog": "Laagste prijs",
        "Naam hoog - laag": "Op naam Z-A",
        "Naam laag - hoog": "Op naam A-Z",
        "Sorteer resultaten op relevantie": "Sorteer resultaten op relevantie",
        "Sorteer resultaten op prijs hoog - laag": "Sorteer resultaten op hoogste prijs",
        "Sorteer resultaten op prijs laag - hoog": "Sorteer resultaten op laagste prijs",
        "Sorteer resultaten op naam hoog - laag": "Sorteer resultaten op naam Z-A",
        "Sorteer resultaten op naam laag - hoog": "Sorteer resultaten op naam A-Z",
        "Gewenste leverdatum": "Gewenste leverdatum",
        "Details bekijken": "Details bekijken",
        "Volume": "Volume",
        "Height": "Hoogte",
        "Length": "Lengte",
        "Width": "Breedte",
        "Weight": "Gewicht",
        "Diameter": "Diameter",
        "Promo tem": "Promo tem",
        "Bewaar bestelling": "Bewaar bestelling",
        "Mijn winkelmandje": "Mijn winkelmandje",
        "subtotaal": "subtotaal",
        "Winkelen": "Winkelen",
        "backorder": "backorder",
        "Themes": "Thema’s",
        "Channels": "Afdelingen",
        "Gratis product": "Gratis product",
        "Details": "Details",
        "Labels": "Labels",
        priceEstimateInfo: "Deze prijs is indicatief. De correcte prijs vind je op je orderbevestiging.",
        "Kies ten vroegste": "Kies ten vroegste",
        "Kies ten laatste": "Kies ten laatste",
        "Kies een andere datum": "Kies een andere datum",

        errors: {
            generalTitle: "Er ging iets mis",
            noPriceInfoAvailable: "Geen prijsinfo voor dit product beschikbaar.",
            noPriceInfoFetched: "We konden geen prijsinfo ophalen voor dit product.",
            noProductSelected: "Geen product geselecteerd.",
            notAddedToCart: "We konden het product niet toevoegen aan jouw winkelmand.",
            notDeletedFromCart: "We konden dit product niet verwijderen uit jouw winkelmand.",
            cartNotUpdated: "We konden jouw winkelmand niet bijwerken.",
            orderNotUpdated: "We konden dit product niet toevoegen aan je bestelling.",
            clientDataNotLoaded: "We konden de klantengegevens niet inladen.",
            apiConnection: "We konden geen connectie maken met de API.",
            noOrderFetched: " We konden jouw bewaarde bestellingen niet ophalen.",
            noProductsInOrder: "Er zitten geen producten in jouw winkelmand.",
            orderNotSaved: "We konden jouw bestelling niet bewaren.",
            orderNotDeleted: "We konden jouw bestelling niet verwijderen.",
            nameOrder: "Geef de bestelling een naam.",
            noOrderFound: "Geen bestelling gevonden.",
            productNotChanged: "We konden dit product niet wijzigen.",
            removedFromBestB: "We konden dit product niet verwijderen uit BestB.",
            minQtyError: "De minimum aankoophoeveelheid voor dit product is {min}",
            stepQtyError: "Product kan enkel worden aangekocht met een veelvoud van {step}",
            validVatNumber: "Gelieve een geldig BTW-nummer in te geven."
        },

        success: {
            addedToCart: "Product aan winkelmand toegevoegd.",
            orderSaved: "Bestelling bewaard!",
            orderDeleted: "Bestelling verwijderd!",
            productDeleted: "Product verwijderd!",
            quantityChanged: "Product aantal gewijzigd.",
            removedFromBestB: "De aanvraag tot verwijderen uit de bestelhistoriek is doorgestuurd. De SPOC doet het nodige hiervoor.",
        },

        // Sorting
        "Sorteren op relevantie": "Sorteren op relevantie",
        "Sorteren op magazijnstructuur": "Sorteren op magazijnstructuur",
        "Sorteren op artikelnummer": "Sorteren op artikelnummer",
        "Sorteren op naam hoog - laag": "Sorteren op naam Z-A",
        "Sorteren op naam laag - hoog": "Sorteren op naam A-Z",

        // Filter labels
        "See all": "Alle",
        "None": "NA",
        "TenS": "T&S – Enkel ontdooien",
        "BFF": "VGR – Voorgerezen",
        "TPB": "CRU – Ongerezen",
        "PB": "VGB – Voorgebakken",

        "⌹": "⌹ Frigo",
        "✿": "✿ Subcon",
        "❆": "❆ Diepvries",
        "☖": "☖ Ambient",
    
        "g": "g",
        "cm": "cm",
        "ml": "ml",
        "Op magazijnstructuur": "Op magazijnstructuur",
        
        // Materialen
        "Aardewerk": "Aardewerk",
        "Acryl": "Acryl",
        "Aluminium": "Aluminium",
        "Aluminium + Teflon": "Aluminium + Teflon",
        "Alusteel + teflon": "Alusteel + teflon",
        "Bamboo": "Bamboo",
        "Blauwstaal": "Blauwstaal",
        "Blik": "Blik",
        "Cacaoboter": "Cacaoboter",
        "Cellofaan": "Cellofaan",
        "Cereal mix": "Cereal mix",
        "Chocolade": "Chocolade",
        "Chocolade/Crunch": "Chocolade/Crunch",
        "Chrome": "Chrome",
        "Composteerbaar materiaal": "Composteerbaar materiaal",
        "Creme": "Creme",
        "Crunch": "Crunch",
        "Dolomiet": "Dolomiet",
        "Dragant": "Dragant",
        "Easymelt": "Easymelt",
        "Exoglas": "Exoglas",
        "Exoglass": "Exoglass",
        "Exopan": "Exopan",
        "Fruitpuree": "Fruitpuree",
        "Gietaluminium": "Gietaluminium",
        "Glas": "Glas",
        "HDPE": "HDPE",
        "Hostie": "Hostie",
        "Hout": "Hout",
        "Inox": "Inox",
        "Inox + Plastiek": "Inox + Plastiek",
        "Jute": "Jute",
        "Karton": "Karton",
        "Karton + PVC": "Karton + PVC",
        "Katoen": "Katoen",
        "Koper": "Koper",
        "Kunstbont": "Kunstbont",
        "Kunststof PP": "Kunststof PP",
        "Lei": "Lei",
        "Lurex": "Lurex",
        "MDF": "MDF",
        "Marsepein": "Marsepein",
        "Marsepein/chocolade": "Marsepein/chocolade",
        "Melamine": "Melamine",
        "Metaal": "Metaal",
        "Metaal-kunststof": "Metaal-kunststof",
        "Natureflex": "Natureflex",
        "Nitril": "Nitril",
        "Non-woven": "Non-woven",
        "Nylon": "Nylon",
        "PE + PP": "PE + PP",
        "PET": "PET",
        "PS": "PS",
        "PVC": "PVC",
        "Papier": "Papier",
        "Pasta": "Pasta",
        "Paste": "Paste",
        "Persipan": "Persipan",
        "Plastiek": "Plastiek",
        "Plastiek/Inox": "Plastiek/Inox",
        "Plastiek/metaal": "Plastiek/metaal",
        "Poeder": "Poeder",
        "Poluethylene": "Poluethylene",
        "Poly GM": "Poly GM",
        "Polycarbonaat": "Polycarbonaat",
        "Polyester": "Polyester",
        "Polyglass": "Polyglass",
        "Polypropyleen": "Polypropyleen",
        "Polypropyleen + Polyester + Roestvrijs staal 5AISI 304": "Polypropyleen + Polyester + Roestvrijs staal 5AISI 304",
        "Polystyrene": "Polystyrene",
        "Populierenhout": "Populierenhout",
        "Porselein": "Porselein",
        "RPET": "RPET",
        "Rayon": "Rayon",
        "Resine": "Resine",
        "Satijn": "Satijn",
        "Silicone": "Silicone",
        "Sizoflor": "Sizoflor",
        "Stof": "Stof",
        "Sugarpaste": "Sugarpaste",
        "Suiker": "Suiker",
        "Suikerrietpulp": "Suikerrietpulp",
        "Tafeta": "Tafeta",
        "Tarwe": "Tarwe",
        "Teflon": "Teflon",
        "Terracotta": "Terracotta",
        "Textiel": "Textiel",
        "Textiel / microleder": "Textiel / microleder",
        "Thermoplastic": "Thermoplastic",
        "Tritan": "Tritan",
        "Velours": "Velours",
        "Vetvrij papier": "Vetvrij papier",
        "Vilt": "Vilt",
        "Vinyl/nitril": "Vinyl/nitril",

        // Stock
        "De stockindicatie heeft geen garantie op tijdig levering.": "De stockindicatie heeft geen garantie op een tijdige levering.",
        "Onmiddelijk leverbaar": "Onmiddellijk leverbaar",
        "Laatste stuks": "Laatste stuks",
        "Met levertermijn": "Met levertermijn",
        "Onmiddellijk leverbaar via leverancier": "Onmiddellijk leverbaar via leverancier",
        "Uit gamma met alternatief": "Uit gamma met alternatief",
        "Stockindicatie": "Stockindicatie",

        "Undefined": "Niet gedefinieerd",
        "Daily": "1 dag",
        "Weekly": "1 week",
        "Biweekly": "2 weken",
        "Monthly": "1 maand",
        "More than one month": "> 1 maand",
        "While stock last": "OP=OP",
        "lastUpdated": "Laatste stock update {date} op {time}",

        "bargainSale": "Uit gamma",

        "RanBakery": "Bakery",
        "RanChocolate": "Chocolate",
        "RanCatering": "Horeca",
        "RanRanshop": "Verpakkingen, klein materiaal en winkelinrichting",
        "Ranshop": "Verpakkingen, klein materiaal en winkelinrichting",

        "Uitverkoop": "Uit gamma",

        "UTZ": "RFA – Rainforest Allaince",
        "utz": "RFA – Rainforest Alliance",
        "Clear": "wissen",
    
        "price": "Prijs",
        "promotionPrice": "Promotieprijs",
        "yourPrice": "Uw prijs",
        "discontinued": "Niet beschikbaar",
        "discontinuedInfoMsg": "Volgende artikel is niet meer beschikbaar. Klik op het mandje om het alternatief te bekijken en te bestellen.",
        "volumeDiscountText": "Of koop er <b>{qty}</b> voor <b>{price}/stuk</b>",
        "volumeDiscountTextMore": "Koop er meer dan <b>{qty}</b> voor <b>{price}/stuk</b>",

        "GA VERDER MET BESTELLEN": "GA VERDER MET BESTELLEN",
        "Verder winkelen": "Verder winkelen",
        "of": "of",
        "Te betalen": "Te betalen",
        "Mijn favorieten": "Mijn favorieten",
        "Gewenste afhaaldatum": "Gewenste afhaaldatum",
        "Geen favorieten gevonden": "Geen favorieten gevonden",

        "bff": "bff-nl",
        "pb": "pb-nl",
        "tens": "tens-nl",
        "tpb": "tpb-nl",
    },
    en: {
        "Show more": "Show more",
        "BestB": "BestB",
        "Doorzoek klanten": "Search customers",
        "Filter": "Filter",
        "Close": "Close",
        "Premium selection": "Premium selection",
        "Private label": "Private label",
        "Bio": "Bio",
        "Bakery": "Bakery",
        "Loading": "Loading",
        "Reset filters": "Reset filters",
        "Prijs": "Price",
        "Hoeveelheid": "Amount",
        "Eenheid": "Unit",
        "Pallet": "Pallet",
        "ProductenPerDoos": "Pieces per unit",
        "AantalPerOmverpakking": "Number of packs in overpack",
        "Opmerking": "Remark",
        "Toevoegen": "Add",
        "Verwijderen": "Remove",
        "Opslaan": "Save",
        "Bestellen": "Order",
        "Aanpassen": "Edit",
        "products - page": "products - page",
        "Vorige periode": "Previous period",
        "Huidige periode": "Current period",
        "Laatst besteld" : "Last ordered",
        "ob": "OD",
        "new": "NEW",
        "promo": "promo",
        "stuks": "pieces",
        "Technical sheet": "Technical sheet",
        "Download technical sheet": "Download technical sheet",
        "Aantal": "Quantity",
        "Algemeen": "General",
        "Categorieën": "Categories",
        "Merken": "Brands",
        "Materiaal": "Material",
        "Aantal in doos": "Pieces per unit",
        "Bak type": "Bake type",
        "Baktijd": "Baking time",
        "Ontdooitijd": "Defrost time",
        "Oven temperatuur": "Oven temperature",
        "Rijstijd": "Rising time",
        "Basisassortiment": "Basic range",
        "Op bestelling": "On demand",
        "Nieuwe producten": "New products",
        "Promoties": "Sales",
        "Log in to order": "Log in to order",
        "tot": "to",
        "Toon bestelhistoriek": "Show order history",
        "Bestelhistoriek": "Order history",
        "Toon alle producten": "Show all products",
        "Alle producten": "All products",
        "Zoek jouw product op nummer of naam": "Search your product by number or name",
        productNotAvailable: "Dear customer, the selected product is not available at the moment, but we can offer you the following alternatives:",
        productsNotAvailable: "Dear customer, some products from your order are not available at the moment, but we can offer you alternatives for the following products:",
        "Doorzoek producten en klanten": "Search products and customers",
        "Products": "Products",
        "Customers": "Customers",
        "Geen klant geselecteerd": "No customer selected",
        "Accept": "OK",
        "Cancel": "Annuleren",
        "Bevestig selectie": "Confirm selection",
        warningDeleteOrder: "<strong class='block text-18 text-red-500'>Are you sure you want to delete this order?</strong>This cannot be undone.",
        "Nieuwe bestelling": "New order",
        "Naam": "Name",
        "Omschrijving": "Description",
        "Totaal": "Total",
        "Artikel": "Article",
        "Bv. Bestelling éclairs": "E.g. Order éclairs",
        "Bv. Alle nodige basisproducten voor éclairs.": "E.g. All necessary basic products for éclairs.",
        "Producten in bestelling": "Products in order",
        "Bestelling verwijderen...": "Deleting order...",
        "Sorteren": "Sorting",
        "Sales": "Sales",
        "Voor sales": "For sales",
        "Gewenste leverdatum": "Desired delivery date",
        "Details bekijken": "View details",
        "Volume": "Volume",
        "Height": "Height",
        "Length": "Length",
        "Width": "Width",
        "Weight": "Weight",
        "Diameter": "Diameter",
        "Promo tem": "Promo until",
        "Bewaar bestelling": "Save order",
        "Mijn winkelmandje": "My shopping cart",
        "subtotaal": "subtotal",
        "Winkelen": "Shop",
        "backorder": "backorder",
        "Themes": "Themes",
        "Channels": "Departements",
        "Gratis product": "Free product",
        "Details": "Details",
        "Labels": "Labels",
        priceEstimateInfo: "This price is indicative. You will find the correct price on your order confirmation.",
        "Kies ten vroegste": "Choose at the earliest",
        "Kies ten laatste": "Choose at the latest",
        "Kies een andere datum": "Choose another date",

        errors: {
            generalTitle: "Something went wrong",
            noPriceInfoAvailable: "No pricing information available for this product.",
            noPriceInfoFetched: "We were unable to retrieve pricing information for this product.",
            noProductSelected: "No product selected.",
            notAddedToCart: "We were unable to add the product to your shopping cart.",
            notDeletedFromCart: "We were unable to remove this product from your shopping cart.",
            cartNotUpdated: "We were unable to update your shopping cart.",
            orderNotUpdated: "We were unable to add this product to your order.",
            clientDataNotLoaded: "We could not load the customer data.",
            apiConnection: "We couldn't connect to the API.",
            noOrderFetched: "We were unable to retrieve your saved orders.",
            noProductsInOrder: "There are no products in your order.",
            orderNotSaved: "Could not save order.",
            orderNotDeleted: "Could not delete order.",
            nameOrder: "Give the order a name.",
            noOrderFound: "No order found.",
            productNotChanged: "We were unable to change this product.",
            removedFromBestB: "We were unable to remove the product from the order history.",
            minQtyError: "The minimum purchase quantity for this product is {min}",
            stepQtyError: "Product can only be purchased in multiples of {step}",
            validVatNumber: "Please enter a valid VAT number."
        },

        success: {
            addedToCart: "Product added to cart.",
            orderSaved: "Order saved",
            orderDeleted: "Order deleted",
            productDeleted: "Product removed",
            quantityChanged: "Product quantity changed.",
            removedFromBestB: "The request to delete from the order history has been forwarded. Your contact will take the necessary steps.",
        },

        // Sorting
        "Sorteren op relevantie": "Sort by Relevance",
        "Sorteren op magazijnstructuur": "Sort by Warehouse Structure",
        "Sorteren op artikelnummer": "Sort by Article Number",
        "Sorteren op naam hoog - laag": "Sort by Name Z-A",
        "Sorteren op naam laag - hoog": "Sort by Name A-Z",

        // Filter labels
        "See all": "See all",
        "None": "NA",
        "TenS": "T&S - Thaw & serve",
        "BFF": "BFF - Bake from frozen",
        "TPB": "TPB - Thaw, prove and bake",
        "PB": "PB - Part baked",

        "⌹": "⌹ Fridge",
        "✿": "✿ Subcon",
        "❆": "❆ Freezer",
        "☖": "☖ Ambient",
        
        "g": "g",
        "cm": "cm",
        "ml": "ml",
        "Op magazijnstructuur": "By warehouse structure",
    
        // Materialen
        "Aardewerk": "Aardewerk",
        "Acryl": "Acryl",
        "Aluminium": "Aluminium",
        "Aluminium + Teflon": "Aluminium + Teflon",
        "Alusteel + teflon": "Alusteel + teflon",
        "Bamboo": "Bamboo",
        "Blauwstaal": "Blauwstaal",
        "Blik": "Blik",
        "Cacaoboter": "Cacaoboter",
        "Cellofaan": "Cellofaan",
        "Cereal mix": "Cereal mix",
        "Chocolade": "Chocolade",
        "Chocolade/Crunch": "Chocolade/Crunch",
        "Chrome": "Chrome",
        "Composteerbaar materiaal": "Composteerbaar materiaal",
        "Creme": "Creme",
        "Crunch": "Crunch",
        "Dolomiet": "Dolomiet",
        "Dragant": "Dragant",
        "Easymelt": "Easymelt",
        "Exoglas": "Exoglas",
        "Exoglass": "Exoglass",
        "Exopan": "Exopan",
        "Fruitpuree": "Fruitpuree",
        "Gietaluminium": "Gietaluminium",
        "Glas": "Glas",
        "HDPE": "HDPE",
        "Hostie": "Hostie",
        "Hout": "Hout",
        "Inox": "Inox",
        "Inox + Plastiek": "Inox + Plastiek",
        "Jute": "Jute",
        "Karton": "Karton",
        "Karton + PVC": "Karton + PVC",
        "Katoen": "Katoen",
        "Koper": "Koper",
        "Kunstbont": "Kunstbont",
        "Kunststof PP": "Kunststof PP",
        "Lei": "Lei",
        "Lurex": "Lurex",
        "MDF": "MDF",
        "Marsepein": "Marsepein",
        "Marsepein/chocolade": "Marsepein/chocolade",
        "Melamine": "Melamine",
        "Metaal": "Metaal",
        "Metaal-kunststof": "Metaal-kunststof",
        "Natureflex": "Natureflex",
        "Nitril": "Nitril",
        "Non-woven": "Non-woven",
        "Nylon": "Nylon",
        "PE + PP": "PE + PP",
        "PET": "PET",
        "PS": "PS",
        "PVC": "PVC",
        "Papier": "Papier",
        "Pasta": "Pasta",
        "Paste": "Paste",
        "Persipan": "Persipan",
        "Plastiek": "Plastiek",
        "Plastiek/Inox": "Plastiek/Inox",
        "Plastiek/metaal": "Plastiek/metaal",
        "Poeder": "Poeder",
        "Poluethylene": "Poluethylene",
        "Poly GM": "Poly GM",
        "Polycarbonaat": "Polycarbonaat",
        "Polyester": "Polyester",
        "Polyglass": "Polyglass",
        "Polypropyleen": "Polypropyleen",
        "Polypropyleen + Polyester + Roestvrijs staal 5AISI 304": "Polypropyleen + Polyester + Roestvrijs staal 5AISI 304",
        "Polystyrene": "Polystyrene",
        "Populierenhout": "Populierenhout",
        "Porselein": "Porselein",
        "RPET": "RPET",
        "Rayon": "Rayon",
        "Resine": "Resine",
        "Satijn": "Satijn",
        "Silicone": "Silicone",
        "Sizoflor": "Sizoflor",
        "Stof": "Stof",
        "Sugarpaste": "Sugarpaste",
        "Suiker": "Suiker",
        "Suikerrietpulp": "Suikerrietpulp",
        "Tafeta": "Tafeta",
        "Tarwe": "Tarwe",
        "Teflon": "Teflon",
        "Terracotta": "Terracotta",
        "Textiel": "Textiel",
        "Textiel / microleder": "Textiel / microleder",
        "Thermoplastic": "Thermoplastic",
        "Tritan": "Tritan",
        "Velours": "Velours",
        "Vetvrij papier": "Vetvrij papier",
        "Vilt": "Vilt",
        "Vinyl/nitril": "Vinyl/nitril",

        // Stock
        "De stockindicatie heeft geen garantie op tijdig levering.": "The stock indication does not guarantee timely delivery.",
        "Onmiddelijk leverbaar": "Immediately available",
        "Laatste stuks": "Last pieces",
        "Met levertermijn": "With delivery time",
        "Onmiddellijk leverbaar via leverancier": "Immediate delivery via the supplier",
        "Uit gamma met alternatief": "Out of range with alternative",
        "Stockindicatie": "Stock indication",

        "Undefined": "Undefined",
        "Daily": "1 day",
        "Weekly": "1 week",
        "Biweekly": "2 weeks",
        "Monthly": "1 month",
        "More than one month": "> 1 month",
        "While stock last": "Discontinue",
        "lastUpdated": "Last stock update {date} at {time}",

        "bargainSale": "Out of range",

        "RanBakery": "Bakery",
        "RanChocolate": "Chocolate",
        "RanIcecream": "Ice Cream",
        "RanCatering": "Horeca",
        "RanRanshop": "Packaging, small equipment and shop fitting",
        "Ranshop": "Packaging, small equipment and shop fitting",

        "Uitverkoop": "Out of range",

        "UTZ": "RFA – Rainforest Alliance",
        "utz": "RFA – Rainforest Alliance",
    
        "price": "Price",
        "promotionPrice": "Promotion price",
        "yourPrice": "Your price",
        "discontinued": "Not available",
        "discontinuedInfoMsg": "The following item is no longer available. Click on the basket to view and order the alternative.",
        "volumeDiscountText": "Buy <b>{qty}</b> for <b>{price}/piece</b>",
        "volumeDiscountTextMore": "Buy more than <b>{qty}</b><br>for <b>{price}/piece</b>\n",
        "GA VERDER MET BESTELLEN": "CONTINUE ORDERING",
        "Verder winkelen": "Continue shopping",
        "of": "or",
        "Te betalen": "To pay",
        "Mijn favorieten": "My favorites",
        "Gewenste afhaaldatum": "Desired pick-up date",
        "Geen favorieten gevonden": "No favorites found",

        "bff": "bff-en",
        "pb": "pb-en",
        "tens": "tens-en",
        "tpb": "tpb-en",
    },
    'en-GB': {
        "Show more": "Show more",
        "BestB": "BestB",
        "Doorzoek klanten": "Search customers",
        "Filter": "Filter",
        "Close": "Close",
        "Premium selection": "Premium selection",
        "Private label": "Private label",
        "Bio": "Bio",
        "Bakery": "Bakery",
        "Loading": "Loading",
        "Reset filters": "Reset filters",
        "Prijs": "Price",
        "AantalPerOmverpakking": "Number of packs in overpack",
        "Hoeveelheid": "Amount",
        "Eenheid": "Unit",
        "Pallet": "Pallet",
        "ProductenPerDoos": "Pieces per unit",
        "Opmerking": "Remark",
        "Toevoegen": "Add",
        "Verwijderen": "Remove",
        "Opslaan": "Save",
        "Bestellen": "Order",
        "Aanpassen": "Edit",
        "products - page": "products - page",
        "Vorige periode": "Previous period",
        "Huidige periode": "Current period",
        "Laatst besteld" : "Last ordered",
        "ob": "OD",
        "new": "NEW",
        "promo": "promo",
        "stuks": "pieces",
        "Technical sheet": "Technical sheet",
        "Download technical sheet": "Download technical sheet",
        "Aantal": "Quantity",
        "Algemeen": "General",
        "Categorieën": "Categories",
        "Merken": "Brands",
        "Materiaal": "Material",
        "Aantal in doos": "Pieces per unit",
        "Bak type": "Bake type",
        "Baktijd": "Baking time",
        "Ontdooitijd": "Defrost time",
        "Oven temperatuur": "Oven temperature",
        "Rijstijd": "Rising time",
        "Basisassortiment": "Basic range",
        "Op bestelling": "On demand",
        "Nieuwe producten": "New products",
        "Promoties": "Sales",
        "Log in to order": "Log in to order",
        "tot": "to",
        "Toon bestelhistoriek": "Show order history",
        "Bestelhistoriek": "Order history",
        "Toon alle producten": "Show all products",
        "Alle producten": "All products",
        "Zoek jouw product op nummer of naam": "Search your product by number or name",
        productNotAvailable: "Dear customer, the selected product is not available at the moment, but we can offer you the following alternatives:",
        productsNotAvailable: "Dear customer, some products from your order are not available at the moment, but we can offer you alternatives for the following products:",
        "Doorzoek producten en klanten": "Search products and customers",
        "Products": "Products",
        "Customers": "Customers",
        "Geen klant geselecteerd": "No customer selected",
        "Accept": "OK",
        "Cancel": "Annuleren",
        "Bevestig selectie": "Confirm selection",
        warningDeleteOrder: "<strong class='block text-18 text-red-500'>Are you sure you want to delete this order?</strong>This cannot be undone.",
        "Nieuwe bestelling": "New order",
        "Naam": "Name",
        "Omschrijving": "Description",
        "Totaal": "Total",
        "Artikel": "Article",
        "Bv. Bestelling éclairs": "E.g. Order éclairs",
        "Bv. Alle nodige basisproducten voor éclairs.": "E.g. All necessary basic products for éclairs.",
        "Producten in bestelling": "Products in order",
        "Bestelling verwijderen...": "Deleting order...",
        "Sorteren": "Sorting",
        "Sales": "Sales",
        "Voor sales": "For sales",
        "Gewenste leverdatum": "Desired delivery date",
        "Details bekijken": "View details",
        "Volume": "Volume",
        "Height": "Height",
        "Length": "Length",
        "Width": "Width",
        "Weight": "Weight",
        "Diameter": "Diameter",
        "Promo tem": "Promo until",
        "Bewaar bestelling": "Save order",
        "Mijn winkelmandje": "My shopping cart",
        "subtotaal": "subtotal",
        "Winkelen": "Shop",
        "backorder": "backorder",
        "Themes": "Themes",
        "Channels": "Departements",
        "Gratis product": "Free product",
        "Details": "Details",
        "Labels": "Labels",
        priceEstimateInfo: "This price is indicative. You will find the correct price on your order confirmation.",
        "Kies ten vroegste": "Choose at the earliest",
        "Kies ten laatste": "Choose at the latest",
        "Kies een andere datum": "Choose another date",

        errors: {
            generalTitle: "Something went wrong",
            noPriceInfoAvailable: "No pricing information available for this product.",
            noPriceInfoFetched: "We were unable to retrieve pricing information for this product.",
            noProductSelected: "No product selected.",
            notAddedToCart: "We were unable to add the product to your shopping cart.",
            notDeletedFromCart: "We were unable to remove this product from your shopping cart.",
            cartNotUpdated: "We were unable to update your shopping cart.",
            orderNotUpdated: "We were unable to add this product to your order.",
            clientDataNotLoaded: "We could not load the customer data.",
            apiConnection: "We couldn't connect to the API.",
            noOrderFetched: "We were unable to retrieve your saved orders.",
            noProductsInOrder: "There are no products in your order.",
            orderNotSaved: "Could not save order.",
            orderNotDeleted: "Could not delete order.",
            nameOrder: "Give the order a name.",
            noOrderFound: "No order found.",
            productNotChanged: "We were unable to change this product.",
            removedFromBestB: "We were unable to remove the product from the order history.",
            minQtyError: "The minimum purchase quantity for this product is {min}",
            stepQtyError: "Product can only be purchased in multiples of {step}",
            validVatNumber: "Please enter a valid VAT number."
        },

        success: {
            addedToCart: "Product added to cart.",
            orderSaved: "Order saved",
            orderDeleted: "Order deleted",
            productDeleted: "Product removed",
            quantityChanged: "Product quantity changed.",
            removedFromBestB: "The request to delete from the order history has been forwarded. Your contact will take the necessary steps.",
        },

        // Sorting
        "Sorteren op relevantie": "Sort by Relevance",
        "Sorteren op magazijnstructuur": "Sort by Warehouse Structure",
        "Sorteren op artikelnummer": "Sort by Article Number",
        "Sorteren op naam hoog - laag": "Sort by Name Z-A",
        "Sorteren op naam laag - hoog": "Sort by Name A-Z",

        // Filter labels
        "See all": "See all",
        "None": "NA",
        "TenS": "T&S - Thaw & serve",
        "BFF": "BFF - Bake from frozen",
        "TPB": "TPB - Thaw, prove and bake",
        "PB": "PB - Part baked",

        "⌹": "⌹ Fridge",
        "✿": "✿ Subcon",
        "❆": "❆ Freezer",
        "☖": "☖ Ambient",

        "g": "g",
        "cm": "cm",
        "ml": "ml",
        "Op magazijnstructuur": "By warehouse structure",

        // Materialen
        "Aardewerk": "Aardewerk",
        "Acryl": "Acryl",
        "Aluminium": "Aluminium",
        "Aluminium + Teflon": "Aluminium + Teflon",
        "Alusteel + teflon": "Alusteel + teflon",
        "Bamboo": "Bamboo",
        "Blauwstaal": "Blauwstaal",
        "Blik": "Blik",
        "Cacaoboter": "Cacaoboter",
        "Cellofaan": "Cellofaan",
        "Cereal mix": "Cereal mix",
        "Chocolade": "Chocolade",
        "Chocolade/Crunch": "Chocolade/Crunch",
        "Chrome": "Chrome",
        "Composteerbaar materiaal": "Composteerbaar materiaal",
        "Creme": "Creme",
        "Crunch": "Crunch",
        "Dolomiet": "Dolomiet",
        "Dragant": "Dragant",
        "Easymelt": "Easymelt",
        "Exoglas": "Exoglas",
        "Exoglass": "Exoglass",
        "Exopan": "Exopan",
        "Fruitpuree": "Fruitpuree",
        "Gietaluminium": "Gietaluminium",
        "Glas": "Glas",
        "HDPE": "HDPE",
        "Hostie": "Hostie",
        "Hout": "Hout",
        "Inox": "Inox",
        "Inox + Plastiek": "Inox + Plastiek",
        "Jute": "Jute",
        "Karton": "Karton",
        "Karton + PVC": "Karton + PVC",
        "Katoen": "Katoen",
        "Koper": "Koper",
        "Kunstbont": "Kunstbont",
        "Kunststof PP": "Kunststof PP",
        "Lei": "Lei",
        "Lurex": "Lurex",
        "MDF": "MDF",
        "Marsepein": "Marsepein",
        "Marsepein/chocolade": "Marsepein/chocolade",
        "Melamine": "Melamine",
        "Metaal": "Metaal",
        "Metaal-kunststof": "Metaal-kunststof",
        "Natureflex": "Natureflex",
        "Nitril": "Nitril",
        "Non-woven": "Non-woven",
        "Nylon": "Nylon",
        "PE + PP": "PE + PP",
        "PET": "PET",
        "PS": "PS",
        "PVC": "PVC",
        "Papier": "Papier",
        "Pasta": "Pasta",
        "Paste": "Paste",
        "Persipan": "Persipan",
        "Plastiek": "Plastiek",
        "Plastiek/Inox": "Plastiek/Inox",
        "Plastiek/metaal": "Plastiek/metaal",
        "Poeder": "Poeder",
        "Poluethylene": "Poluethylene",
        "Poly GM": "Poly GM",
        "Polycarbonaat": "Polycarbonaat",
        "Polyester": "Polyester",
        "Polyglass": "Polyglass",
        "Polypropyleen": "Polypropyleen",
        "Polypropyleen + Polyester + Roestvrijs staal 5AISI 304": "Polypropyleen + Polyester + Roestvrijs staal 5AISI 304",
        "Polystyrene": "Polystyrene",
        "Populierenhout": "Populierenhout",
        "Porselein": "Porselein",
        "RPET": "RPET",
        "Rayon": "Rayon",
        "Resine": "Resine",
        "Satijn": "Satijn",
        "Silicone": "Silicone",
        "Sizoflor": "Sizoflor",
        "Stof": "Stof",
        "Sugarpaste": "Sugarpaste",
        "Suiker": "Suiker",
        "Suikerrietpulp": "Suikerrietpulp",
        "Tafeta": "Tafeta",
        "Tarwe": "Tarwe",
        "Teflon": "Teflon",
        "Terracotta": "Terracotta",
        "Textiel": "Textiel",
        "Textiel / microleder": "Textiel / microleder",
        "Thermoplastic": "Thermoplastic",
        "Tritan": "Tritan",
        "Velours": "Velours",
        "Vetvrij papier": "Vetvrij papier",
        "Vilt": "Vilt",
        "Vinyl/nitril": "Vinyl/nitril",

        "bargainSale": "Out of range",

        "RanBakery": "Bakery",
        "RanChocolate": "Chocolate",
        "RanIcecream": "Ice Cream",
        "RanCatering": "Horeca",
        "RanRanshop": "Packaging, small equipment and shop fitting",
        "Ranshop": "Packaging, small equipment and shop fitting",
        "Uitverkoop": "Out of range",

        "UTZ": "RFA – Rainforest Alliance",
        "utz": "RFA – Rainforest Alliance",
        "Clear": "clear",

        // Stock
        "De stockindicatie heeft geen garantie op tijdig levering.": "The stock indication does not guarantee timely delivery.",
        "Onmiddelijk leverbaar": "Immediately available",
        "Laatste stuks": "Last pieces",
        "Met levertermijn": "With delivery time",
        "Onmiddellijk leverbaar via leverancier": "Immediate delivery via the supplier",
        "Uit gamma met alternatief": "Out of range with alternative",
        "Stockindicatie": "Stock indication",

        "Undefined": "Undefined",
        "Daily": "1 day",
        "Weekly": "1 week",
        "Biweekly": "2 weeks",
        "Monthly": "1 month",
        "More than one month": "> 1 month",
        "While stock last": "Discontinue",
        "lastUpdated": "Last stock update {date} at {time}",
    
        "price": "Price",
        "promotionPrice": "Promotion price",
        "yourPrice": "Your price",
        "discontinued": "Not available",
        "discontinuedInfoMsg": "The following item is no longer available. Click on the basket to view and order the alternative.",
        "volumeDiscountText": "Buy <b>{qty}</b> for <b>{price}/piece</b>",
        "volumeDiscountTextMore": "Buy more than <b>{qty}</b><br>for <b>{price}/piece</b>",
        "GA VERDER MET BESTELLEN": "CONTINUE ORDERING",
        "Verder winkelen": "Continue shopping",
        "of": "or",
        "Te betalen": "To pay",
        "Mijn favorieten": "My favorites",
        "Gewenste afhaaldatum": "Desired pick-up date",
        "Geen favorieten gevonden": "No favorites found",

        "bff": "bff-en",
        "pb": "pb-en",
        "tens": "tens-en",
        "tpb": "tpb-en",
    },
    fr: {
        "Show more": "Afficher plus",
        "BestB": "BestB",
        "Filter": "Filter",
        "Close": "Fermer",
        "Premium selection": "Premium selection",
        "Private label": "Private label",
        "Bakery": "Bakery",
        "Bio": "Bio",
        "Doorzoek klanten": "Rechercher des clients",
        "Loading": "Chargement",
        "Reset filters": "Réinitialiser les filtres",
        "Prijs": "Prix",
        "AantalPerOmverpakking": "Nombre de paquets dans l'emballage extérieur",
        "Hoeveelheid": "Quantité",
        "Eenheid": "Emballage",
        "Pallet": "Palette",
        "ProductenPerDoos": "Pièces par emballage ",
        "Opmerking": "Remarque",
        "Toevoegen": "Ajouter",
        "Verwijderen": "Supprimer",
        "Opslaan": "Sauvegarder",
        "Bestellen": "Commander",
        "Aanpassen": "Ajuster",
        "products - page": "produits - page",
        "Vorige periode": "Période passée",
        "Huidige periode": "Période actuelle",
        "Laatst besteld" : "Dernière commande",
        "ob": "SC",
        "new": "NEW",
        "promo": "promo",
        "stuks": "pièces",
        "Technical sheet": "Fiche technique",
        "Download technical sheet": "Télécharger la fiche technique",
        "Aantal": "Nombre",
        "Algemeen": "Général",
        "Categorieën": "Catégories",
        "Merken": "Marques",
        "Materiaal": "Matériel",
        "Aantal in doos": "Pièces par emballage",
        "Bak type": "Type de cuisson",
        "Baktijd": "Temps de cuisson",
        "Ontdooitijd": "Temps de décongélation",
        "Oven temperatuur": "Température du four",
        "Rijstijd": "Temps de montée",
        "Basisassortiment": "Gamme de base",
        "Op bestelling": "Sur commande",
        "Nieuwe producten": "Nouveaux produits",
        "Promoties": "Promotions",
        "Log in to order": "Connectez-vous pour commander",
        "tot": "jusqu'à",
        "Toon bestelhistoriek": "Historique des commandes",
        "Bestelhistoriek": "Historique des commandes",
        "Toon alle producten": "Tous les produits",
        "Alle producten": "Tous les produits",
        "Zoek jouw product op nummer of naam": "Rechercher le produit par numéro ou par nom",
        productNotAvailable: "Le produit sélectionné n'est pas disponible pour le moment, nous pouvons vous proposer les alternatives suivantes :",
        productsNotAvailable: "Certains produits de votre commande ne sont pas disponibles pour le moment, mais nous pouvons vous proposer des alternatives pour les produits suivants :",
        "Doorzoek producten en klanten": "Rechercher des produits et des clients",
        "Products": "Produits",
        "Customers": "Clients",
        "Geen klant geselecteerd": "Aucun client sélectionné",
        "Accept": "D'accord",
        "Cancel": "Annuler",
        "Bevestig selectie": "Confirmer la sélection",
        warningDeleteOrder: "<strong class='block text-18 text-red-500'>Voulez-vous vraiment supprimer cette commande?</strong>Ça ne peut pas être annulé.",
        "Nieuwe bestelling": "Nouvelle commande",
        "Naam": "Nom",
        "Omschrijving": "Description",
        "Totaal": "Total",
        "Artikel": "Article",
        "Bv. Bestelling éclairs": "Par ex. Commander des éclairs",
        "Bv. Alle nodige basisproducten voor éclairs.": "Par ex. Tous les produits de base nécessaires pour les éclairs.",
        "Producten in bestelling": "Produits sur commande",
        "Bestelling verwijderen...": "Supprimer la commande...",
        "Sorteren": "Trier",
        "Sales": "Représentant",
        "Voor sales": "Pour représentant",
        "Gewenste leverdatum": "Date de livraison souhaitée",
        "Details bekijken": "Voir les détails",
        "Volume": "Volume",
        "Height": "Hauteur",
        "Length": "Longueur",
        "Width": "Largeur",
        "Weight": "Poids",
        "Diameter": "Diamètre",
        "Promo tem": "Promo jusqu'à",
        "Bewaar bestelling": "Enregistrer la commande",
        "Mijn winkelmandje": "Mon panier",
        "subtotaal": "total",
        "Winkelen": "Faire du shopping",
        "backorder": "backorder",
        "Themes": "Thèmes",
        "Channels": "Départements",
        "Gratis product": "Produit gratuit",
        "Details": "Details",
        "Labels": "Labels",
        priceEstimateInfo: "Ce prix est indicatif. Vous trouverez le prix exact sur votre confirmation de commande.",
        "Kies ten vroegste": "Choisissez au plus tôt",
        "Kies ten laatste": "Choisissez au plus tard",
        "Kies een andere datum": "Choisissez une autre date",

        errors: {
            generalTitle: "Il y a eu un problème",
            noPriceInfoAvailable: "Aucune information sur les prix n'est disponible pour ce produit.",
            noPriceInfoFetched: "Nous ne pouvons pas recueillir d'informations sur les prix de ce produit",
            noProductSelected: "Aucun produit sélectionné.",
            notAddedToCart: "Nous ne pouvons pas ajouter le produit à votre panier.",
            notDeletedFromCart: "Nous ne pouvons pas retirer ce produit de votre panier.",
            cartNotUpdated: "Nous ne pouvons pas mettre à jour votre panier.",
            orderNotUpdated: "Nous ne pouvons pas ajouter ce produit à votre commande.",
            clientDataNotLoaded: "Nous ne pouvons pas charger les données client.",
            apiConnection: "Nous ne pouvons pas nous connecter à l'API.",
            noOrderFetched: "Nous ne pouvons pas récupérer vos commandes enregistrées.",
            noProductsInOrder: "Il n'y a aucun produit dans votre panier.",
            orderNotSaved: "Ce n'est pas possible d'enregistrer la commande.",
            orderNotDeleted: "Ce n'est pas possible de supprimer la commande.",
            nameOrder: "Donnez un nom à la commande.",
            noOrderFound: "Aucune commande trouvée.",
            productNotChanged: "Nous ne pouvons pas changer ce produit.",
            removedFromBestB: "Nous n'avons pas pu supprimer le produit de l'historique des commandes.",
            minQtyError: "La quantité minimale d'achat pour ce produit est de {min}.",
            stepQtyError: "Le produit ne peut être acheté qu'en multiples de {step}.",
            validVatNumber: "Veuillez saisir un numéro de TVA valide."
        },

        success: {
            addedToCart: "Produit ajouté au panier.",
            orderSaved: "Commande enregistrée",
            orderDeleted: "Commande supprimée",
            productDeleted: "Produit supprimé",
            quantityChanged: "La quantité de produit a changé.",
            removedFromBestB: "La demande de suppression de l'historique des commandes a été transmise. Le SPOC prendra les mesures nécessaires.",
        },

        // Sorting
        "Sorteren op relevantie": "Trier par pertinence",
        "Sorteren op magazijnstructuur": "Trier par structure d'entrepôt",
        "Sorteren op artikelnummer": "Trier par numéro de l'article",
        "Sorteren op naam hoog - laag": "Trier par nom Z-A",
        "Sorteren op naam laag - hoog": "Trier par nom A-Z",

        // Filter labels
        "See all": "Tous",
        "None": "NA",
        "TenS": "T&S - Seulement dégeler",
        "BFF": "PAC - Prêt à cuire",
        "TPB": "CRU - Prêt à pousser",
        "PB": "PC - Précuit",

        "⌹": "⌹ Réfrigérateur ",
        "✿": "✿ Subcon",
        "❆": "❆ Congélateur",
        "☖": "☖ Ambient",
    
        "g": "g",
        "cm": "cm",
        "ml": "ml",
        "Op magazijnstructuur": "Par structure d'entrepôt",
    
        // Materialen
        "Aardewerk": "Aardewerk",
        "Acryl": "Acryl",
        "Aluminium": "Aluminium",
        "Aluminium + Teflon": "Aluminium + Teflon",
        "Alusteel + teflon": "Alusteel + teflon",
        "Bamboo": "Bamboo",
        "Blauwstaal": "Blauwstaal",
        "Blik": "Blik",
        "Cacaoboter": "Cacaoboter",
        "Cellofaan": "Cellofaan",
        "Cereal mix": "Cereal mix",
        "Chocolade": "Chocolade",
        "Chocolade/Crunch": "Chocolade/Crunch",
        "Chrome": "Chrome",
        "Composteerbaar materiaal": "Composteerbaar materiaal",
        "Creme": "Creme",
        "Crunch": "Crunch",
        "Dolomiet": "Dolomiet",
        "Dragant": "Dragant",
        "Easymelt": "Easymelt",
        "Exoglas": "Exoglas",
        "Exoglass": "Exoglass",
        "Exopan": "Exopan",
        "Fruitpuree": "Fruitpuree",
        "Gietaluminium": "Gietaluminium",
        "Glas": "Glas",
        "HDPE": "HDPE",
        "Hostie": "Hostie",
        "Hout": "Hout",
        "Inox": "Inox",
        "Inox + Plastiek": "Inox + Plastiek",
        "Jute": "Jute",
        "Karton": "Karton",
        "Karton + PVC": "Karton + PVC",
        "Katoen": "Katoen",
        "Koper": "Koper",
        "Kunstbont": "Kunstbont",
        "Kunststof PP": "Kunststof PP",
        "Lei": "Lei",
        "Lurex": "Lurex",
        "MDF": "MDF",
        "Marsepein": "Marsepein",
        "Marsepein/chocolade": "Marsepein/chocolade",
        "Melamine": "Melamine",
        "Metaal": "Metaal",
        "Metaal-kunststof": "Metaal-kunststof",
        "Natureflex": "Natureflex",
        "Nitril": "Nitril",
        "Non-woven": "Non-woven",
        "Nylon": "Nylon",
        "PE + PP": "PE + PP",
        "PET": "PET",
        "PS": "PS",
        "PVC": "PVC",
        "Papier": "Papier",
        "Pasta": "Pasta",
        "Paste": "Paste",
        "Persipan": "Persipan",
        "Plastiek": "Plastiek",
        "Plastiek/Inox": "Plastiek/Inox",
        "Plastiek/metaal": "Plastiek/metaal",
        "Poeder": "Poeder",
        "Poluethylene": "Poluethylene",
        "Poly GM": "Poly GM",
        "Polycarbonaat": "Polycarbonaat",
        "Polyester": "Polyester",
        "Polyglass": "Polyglass",
        "Polypropyleen": "Polypropyleen",
        "Polypropyleen + Polyester + Roestvrijs staal 5AISI 304": "Polypropyleen + Polyester + Roestvrijs staal 5AISI 304",
        "Polystyrene": "Polystyrene",
        "Populierenhout": "Populierenhout",
        "Porselein": "Porselein",
        "RPET": "RPET",
        "Rayon": "Rayon",
        "Resine": "Resine",
        "Satijn": "Satijn",
        "Silicone": "Silicone",
        "Sizoflor": "Sizoflor",
        "Stof": "Stof",
        "Sugarpaste": "Sugarpaste",
        "Suiker": "Suiker",
        "Suikerrietpulp": "Suikerrietpulp",
        "Tafeta": "Tafeta",
        "Tarwe": "Tarwe",
        "Teflon": "Teflon",
        "Terracotta": "Terracotta",
        "Textiel": "Textiel",
        "Textiel / microleder": "Textiel / microleder",
        "Thermoplastic": "Thermoplastic",
        "Tritan": "Tritan",
        "Velours": "Velours",
        "Vetvrij papier": "Vetvrij papier",
        "Vilt": "Vilt",
        "Vinyl/nitril": "Vinyl/nitril",

        // Stock
        "De stockindicatie heeft geen garantie op tijdig levering.": "L'indication du stock ne garantit pas une livraison dans les délais.",
        "Onmiddelijk leverbaar": "Disponible immédiatement",
        "Laatste stuks": "Dernières pièces",
        "Met levertermijn": "Avec délai de livraison",
        "Onmiddellijk leverbaar via leverancier": "Livraison immédiate par le fournisseur",
        "Uit gamma met alternatief": "Hors de gamme avec alternative",
        "Stockindicatie": "Indication du stock",

        "Undefined": "Indéfini",
        "Daily": "1 jour",
        "Weekly": "1 semaine",
        "Biweekly": "2 semaines",
        "Monthly": "1 mois",
        "More than one month": "> 1 mois",
        "While stock last": "FINI=FINI",
        "lastUpdated": "Dernière mise à jour du stock {date} à {time}",

        "bargainSale": "Hors gamme",

        "RanBakery": "Bakery",
        "RanChocolate": "Chocolate",
        "RanIcecream": "Ice Cream",
        "RanCatering": "Horeca",
        "RanRanshop": "Emballage, petit matériel et aménagement de magasins",
        "Ranshop": "Emballage, petit matériel et aménagement de magasins",
        "Uitverkoop": "Hors gamme",

        "UTZ": "RFA – Rainforest Alliance",
        "utz": "RFA – Rainforest Alliance",
        "Clear": "effacer",
    
        "price": "Prix",
        "promotionPrice": "Prix promotionnel",
        "yourPrice": "Votre prix",
        "discontinued": "Non disponible",
        "discontinuedInfoMsg": "L'article suivant n'est plus disponible. Cliquez sur le panier pour voir et commander l'alternative.",
        "volumeDiscountText": "Achetez <b>{qty}</b> pour <b>{price}/pièce",
        "volumeDiscountTextMore": "Achetez plus de <b>{qty}</b><br>pour <b>{price}/pièce</b>",

        "GA VERDER MET BESTELLEN": "CONTINUER À COMMANDER",
        "Verder winkelen": "Continuer vos achats",
        "of": "ou",
        "Te betalen": "A payer",
        "Mijn favorieten": "Mes favoris",
        "Gewenste afhaaldatum": "Date de collecte souhaitée",
        "Geen favorieten gevonden": "Aucun favori trouvé",

        "bff": "bff-fr",
        "pb": "pb-fr",
        "tens": "tens-fr",
        "tpb": "tpb-fr",
    }
}

export default translations;
